import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { resetPrompts } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/readerSettings';
import { openURL } from '../../utils/openURL';
import { Dialog } from '../Dialog';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './GhostreaderPromptsOptionsDropdown.module.css';

type Props = {
  onResetAllPrompts: () => void;
};

export default function GhostreaderPromptsOptionsDropdown({ onResetAllPrompts }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [showResetPromptsDialog, setShowResetPromptsDialog] = useState(false);
  const history = useHistory();

  const options = useMemo(() => {
    return [
      {
        type: DropdownOptionType.Item,
        name: 'View Ghostreader help',
        onSelect: () => {
          openURL(
            'https://readwise.notion.site/Ghostreader-Prompts-Guide-ecfa2720943d43b2bf9b2fb201de37cf?pvs=4',
            '_blank',
          );
        },
      },
      {
        type: DropdownOptionType.Item,
        name: 'Add OpenAI API Key',
        onSelect: () => history.push('/integrations'),
      },
      {
        type: DropdownOptionType.Separator,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Reset all to default',
        isDanger: true,
        onSelect: () => setShowResetPromptsDialog(true),
      },
    ];
  }, [history]);

  return (
    <>
      <Dropdown
        options={options}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        appendToDocumentBody
        contentClassName={styles.dropdownContent}
        triggerClassName={styles.triggerElement}
      />
      {showResetPromptsDialog && (
        <Dialog
          id="delete-tags"
          title="Reset all customized prompts?"
          subtitle="Are you sure you want to reset all prompts to default?"
          actionTitle="Reset all prompts"
          cancelTitle="Cancel"
          redActionButton
          action={() => {
            setShowResetPromptsDialog(false);
            resetPrompts();
            onResetAllPrompts();
          }}
          cancelAction={() => setShowResetPromptsDialog(false)}
        />
      )}
    </>
  );
}
